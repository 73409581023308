import Promise from 'promise-polyfill';

/*
Minimal fetch polyfill based on unfetch (https://github.com/developit/unfetch):
- allows polyfilling Promises without changing the global namespace,
- supports just GET,
- omits unused features (like credentials, request headers, or returning blobs)
*/

export default (typeof fetch === 'function'
  ? fetch.bind()
  : url => new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open('get', url, true);
    const response = () => ({
      ok: ((request.status / 100) | 0) === 2, /* HTTP 200-299 */ // eslint-disable-line no-bitwise
      json: () => Promise.resolve(request.responseText).then(JSON.parse)
    });
    request.onload = () => {
      resolve(response());
    };
    request.send();
  }));
