import scriptUrl from '@helb/script-url';
import config from './config';
import fetchBarData from './fetchBarData';
import { a, div, span } from './html';
import { omnibarLink } from './omnibarLink';
import readMetaTag from './meta';
import isFlavorSupported from './flavor';
import { browserLanguage, isLanguageSupported } from './language';
import log from './log';
import logoImage from './logo.svg';
import arrowImage from './arrow.svg';
import style from './bar.scss';

export default class CzNicOmnibar {
  constructor(params, oldStyleFlavor) {
    let givenParams = {};

    if (typeof params === 'object') { // new style calls: bar({…})
      givenParams = params;
    }
    if (typeof params === 'string' && isLanguageSupported(params, config)) { // old style calls: bar("en")
      givenParams.language = params;
    }
    if (typeof oldStyleFlavor === 'string' && isFlavorSupported(oldStyleFlavor, config)) { // old style calls: bar("en", "geek")
      givenParams.flavor = oldStyleFlavor;
    }

    const defaults = {
      language: browserLanguage(config),
      flavor: 'basic',
      autolaunch: true,
      opened: false,
      position: 'top'
    };
    const options = { ...defaults, ...givenParams };

    if (!isLanguageSupported(options.language, config)) {
      options.language = config.fallbackLanguage;
    }

    if (!Object.keys(config.flavors).includes(options.flavor)) {
      options.flavor = 'basic';
    }

    if (typeof options.autolaunch !== 'boolean') {
      options.autolaunch = true;
    }

    if (typeof options.opened !== 'boolean') {
      options.opened = false;
    }

    if (!['top', 'bottom'].includes(options.position)) {
      options.position = 'top';
    }

    const metaFlavor = readMetaTag('flavor');
    if (metaFlavor && isFlavorSupported(metaFlavor, config)) {
      this.flavor = metaFlavor;
    } else {
      this.flavor = options.flavor;
    }

    if (readMetaTag('autolaunch') === 'off') {
      this.autolaunch = false;
    } else if (readMetaTag('autolaunch') === 'on') {
      this.autolaunch = true;
    } else {
      this.autolaunch = options.autolaunch;
    }

    const metaPosition = readMetaTag('position');
    if (metaPosition && ['top', 'bottom'].includes(metaPosition)) {
      this.position = metaPosition;
    } else {
      this.position = options.position;
    }

    this.language = options.language.toLowerCase();
    this.opened = options.opened;
    this.rootElement = div();
    this.rootElement.setAttribute('role', 'complementary');
    this.rootElement.id = 'tb-projects-bar';
    this.rootElement.className = `${config.baseCss}-root`;
    this.rootElement.classList.add(`${config.baseCss}-root-position-${this.position}`);
    this.rootElement.setAttribute('data-omnibar-version', '__gitTagOrBranch__');
    this.rootElement.setAttribute('data-omnibar-git-hash', '__gitHash__');

    if (this.opened) {
      this.open();
    } else {
      this.close();
    }

    if (this.autolaunch) {
      if (document.body === null) {
        document.addEventListener('DOMContentLoaded', () => this.mount());
      } else {
        this.mount();
      }
    }
  }

  mount() {
    this.render(this.language);
    if (this.position === 'top') {
      document.body.insertBefore(this.rootElement, document.querySelector('body > *'));
    } else if (this.position === 'bottom') {
      document.body.appendChild(this.rootElement);
    }
    this.insertCss();
  }

  unmount() {
    this.removeCss();
    this.rootElement.remove();
  }

  open() {
    this.opened = true;
    this.rootElement.setAttribute('aria-expanded', 'true');
    this.rootElement.classList.remove(`${config.baseCss}-root-closed`);
    this.rootElement.classList.add(`${config.baseCss}-root-opened`);
    const text = this.rootElement.querySelector(`.${config.baseCss}-more-text`);
    if (text) {
      text.innerText = config.languages[this.language].less;
    }
    if (this.position === 'bottom') {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  close() {
    if (this.opened === true) {
      const text = this.rootElement.querySelector(`.${config.baseCss}-more-text`);
      if (text) {
        text.innerText = config.languages[this.language].more;
      }
    }
    this.opened = false;
    this.rootElement.setAttribute('aria-expanded', 'false');
    this.rootElement.classList.remove(`${config.baseCss}-root-opened`);
    this.rootElement.classList.add(`${config.baseCss}-root-closed`);
  }

  insertCss() {
    // #if _CSS_FILE
    const cssUrl = scriptUrl().replace(/\/[^/]+\.js$/, '/omnibar.css');
    const styleLink = document.createElement('link');
    styleLink.id = 'omnibar-ng';
    styleLink.rel = 'stylesheet';
    styleLink.href = cssUrl;
    styleLink.addEventListener('error', () => log(`Failed to load stylesheet from '${cssUrl}'.`));
    document.head.appendChild(styleLink);
    // #else
    const styleElement = document.createElement('style');
    styleElement.id = 'omnibar-ng';
    styleElement.type = 'text/css';
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = style;
    } else {
      styleElement.appendChild(document.createTextNode(style));
    }
    document.head.appendChild(styleElement);
    // #endif
  }

  removeCss() {
    // #if _CSS_FILE
    const styleLink = document.head.querySelector('link#omnibar-ng');
    document.head.removeChild(styleLink);
    // #else
    const styleElement = document.head.querySelector('style#omnibar-ng');
    document.head.removeChild(styleElement);
    // #endif
  }

  toggle() {
    if (!this.opened) {
      this.open();
    } else {
      this.close();
    }
  }

  switchLanguage(newLangCode) {
    if (this.language !== newLangCode) {
      if (!isLanguageSupported(newLangCode, config)) {
        log(`Unsupported language "${newLangCode}", `
            + `switching to fallback language "${config.fallbackLanguage}".`, 'warning');
        this.switchLanguage(config.fallbackLanguage);
      } else {
        this.language = newLangCode;
        this.render();
      }
    }
  }

  switchFlavor(newFlavor) {
    if (isFlavorSupported(newFlavor, config)) {
      this.flavor = newFlavor;
      this.render();
    }
  }

  getData(flavor) {
    return fetchBarData(config.flavors[flavor]);
  }

  render() {
    this.getData(this.flavor).then((data) => {
      if (data) {
        const lang = config.languages[this.language];
        const logoContainer = a();
        const linkContainer = div();
        logoContainer.href = config.logoLink;
        linkContainer.className = `${config.baseCss}-link-container`;
        if (this.position === 'top') {
          this.rootElement.innerHTML = `<a href='#${config.baseCss}-jump'`
                                       + `class="${config.baseCss}-jump">${lang.jump}</a>`;
        }
        logoContainer.innerHTML = `${logoImage}<span class="${config.baseCss}-logo-alt">`
                                  + `${config.logoAlt}</span>`;
        logoContainer.className = `${config.baseCss}-logo`;

        const links = data.map(link => omnibarLink(link, this.language));
        const arrowContainer = span();
        arrowContainer.className = `${config.baseCss}-more-arrow`;
        arrowContainer.innerHTML = arrowImage;
        const moreButton = document.createElement('button');
        moreButton.addEventListener('click', () => this.toggle());
        moreButton.className = `${config.baseCss}-more`;
        moreButton.innerHTML = `${arrowContainer.outerHTML}`
                               + `<span class='${config.baseCss}-more-text'>`
                               + `${this.opened ? lang.less : lang.more}</span>`;
        let jumpLink = null;
        if (this.position === 'top') {
          jumpLink = a();
          jumpLink.name = `${config.baseCss}-jump`;
          jumpLink.id = `${config.baseCss}-jump`;
          jumpLink.setAttribute('aria-controls', this.rootElement.id);
          jumpLink.tabIndex = -1;
        }
        links.forEach(link => linkContainer.appendChild(link));
        this.rootElement.appendChild(logoContainer);
        this.rootElement.appendChild(linkContainer);
        this.rootElement.appendChild(moreButton);
        if (this.position === 'top') {
          this.rootElement.appendChild(jumpLink);
        }
        this.moreButton = moreButton;
      } else {
        this.unmount();
      }
    });
  }
}
