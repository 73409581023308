import fetch from './fetch';
import log from './log';

export default url => fetch(url)
  .then(
    r => (r.ok ? r.json() : null),
    e => log(`fetch failed: "${e}", url: ${url}`)
  )
  .then(
    j => (j ? j.topBarData : null),
    e => log(`json parsing failed: "${e}", url: ${url}`)
  );
