import 'core-js/features/array/includes';

import Omnibar from './index';
import readMetaTag from './meta';

window.nicWidgets = window.nicWidgets || {};
window.nicWidgets.topBar = Omnibar;

document.addEventListener('DOMContentLoaded', () => {
  if (readMetaTag('autolaunch') === 'on') {
    window.omnibar = new Omnibar();
  }
});
