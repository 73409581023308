export default {
  flavors: {
    basic: 'https://www.nic.cz/files/CORS/projects-bar/basic-datafile.json',
    geek: 'https://www.nic.cz/files/CORS/projects-bar/basic-datafile-geek.json',
    'basic-datafile.json':
      'https://www.nic.cz/files/CORS/projects-bar/basic-datafile.json',
    'basic-datafile-geek.json':
      'https://www.nic.cz/files/CORS/projects-bar/basic-datafile-geek.json'
  },
  logoLink: 'https://www.nic.cz/',
  logoAlt: 'CZ.NIC',
  fallbackLanguage: 'en',
  languages: {
    en: {
      jump: 'Skip to web page',
      more: 'more',
      less: 'less'
    },
    cs: {
      jump: 'Přeskočit na stránku',
      more: 'více',
      less: 'méně'
    }
  },
  baseCss: 'cznic-omnibar'
};
