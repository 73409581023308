import { a, div } from './html.js';
import config from './config.js';

const getLinkHref = (link, language) => (language === 'cs' ? link[2] : link[3] || link[2]);

const getLinkText = (link, language) => (language === 'cs' ? link[0] : link[1] || link[0]);

function omnibarLink(link, language = config.fallbackLanguage) {
  const linkContainer = div();
  const linkElement = a();
  const href = getLinkHref(link, language);
  const text = getLinkText(link, language);
  if (!href || !text) {
    return null;
  }
  linkElement.href = href;
  linkElement.innerText = text;
  linkContainer.className = `${config.baseCss}-link`;
  linkContainer.appendChild(linkElement);
  return linkContainer;
}

export { omnibarLink, getLinkHref, getLinkText };
